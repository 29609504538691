import React, { useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";

export const Social = () => {
  const [image, setImage] = useState(false);
  const [image2, setImage2] = useState(false);
  return (
    <div className="flex xl:flex-col  items-center gap-5">
      {" "}
      <div className="flex items-center gap-3 justify-center">
        <a
          href="https://t.me/+x42KMbytizAyZWE8"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center rounded-full size-14 hover:bg-primary cursor-pointer z-50 bg-primary/20 border border-primary text-primary hover:text-black relative "
        >
          {" "}
          <FaTelegramPlane className="size-[24px]" />
        </a>
        <a
          href="https://x.com/boysclubpls"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center rounded-full size-14 hover:bg-primary cursor-pointer z-50 bg-primary/20 border border-primary text-primary hover:text-black relative "
        >
          {" "}
          <RiTwitterXFill className="size-[24px]" />
        </a>

        {/* Dexscreener  */}
        <a
          href="https://dexscreener.com/pulsechain/0xeb6d35a9bf045f1adf1c6b3e9d1d5772e5f82138"
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => setImage(true)}
          onMouseLeave={() => setImage(false)}
          className="flex items-center justify-center rounded-full size-14 hover:bg-primary cursor-pointer z-50 bg-primary/20 border border-primary text-primary hover:text-black relative "
        >
          {!image ? (
            <img
              src={"/assets/images/dex_off.png"}
              alt="dexscreener landwolf"
            />
          ) : (
            <img src={"/assets/images/dex_on.png"} alt="dexscreener landwolf" />
          )}
        </a>

        {/* Raydium */}
        <a
          href="https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/?outputCurrency=0xa219C24353B59bEE9F535C4402c11C476A49ACA9"
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => setImage2(true)}
          onMouseLeave={() => setImage2(false)}
          className="flex items-center justify-center rounded-full size-14 hover:bg-primary cursor-pointer z-50 bg-primary/20 border border-primary text-primary hover:text-black relative "
        >
          {!image2 ? (
            <img
              src={"/assets/images/pulsex-off.png"}
              alt="pulsex buy landwolf token"
            />
          ) : (
            <img
              src={"/assets/images/pulsex_on.png"}
              alt="Pulsex buy landwolf token"
            />
          )}
        </a>
      </div>
    </div>
  );
};

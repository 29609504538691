import React from "react";

function About() {
  return (
    <div className="mt-20  relative">
      {/* <img
        src="/assets/images/circle.png"
        className="absolute right-0 -top-80 md:hidden hidden"
        alt="circle"
      /> */}
      <div className="flex flex-row-reverse lg:flex-col-reverse justify-center items-center gap-32 lg:gap-0 max-w-[1500px] mx-auto text-white xxl:px-4">
        <div className="w-1/2  lg:mt-[-50px] relative z-20 lg:w-full space-y-5">
          <div className=" p-10  rounded-[20px] bg-[#7a4bb8]  z-30">
            <h1 className="text-5xl md:text-3xl text-nowrap text-primary uppercase font-MonumentExtendedUltrabold mb-10">
              landwolf
            </h1>
            <p className="font-MonumentExtended text-base relative z-10">
            Landwolf ($WOLF) on PulseChain is a Tang Gang adjacent token launched by the team at the Official Boys' Club. It derives its underlying value from its burned LP, which was initially paired with $NBA. The project's entire supply was launched in LP on 9mm Dex and subsequently sent to the official PulseChain 0x...369 burn address. On the Pulse network, $WOLF is liquidity-bonded to multiple tokens, including $PEPE, $ANDY, $BRETT, and $BDOG.
            </p>
            <br />
            <p className="font-MonumentExtended text-base relative z-10">
            The Boys' Club is the foremost narrative of this crypto cycle, and we firmly believe that PulseChain will be the foremost blockchain. We provide effective leverage on PLS in a fun and community-focused way. Join our weekly Twitter Space to chill with the Boys' Club community.            </p>
            <br />
            {/* <p className="font-MonumentExtended text-base relative z-10">
            Our unmatched perspicacity coupled with our sheer indefatigability makes us feared opponents in any realm of human endeavour, and crypto will be no different.
            </p> */}
          </div>
        </div>
        <div className="w-1/2 md:w-full mx-auto relative z-20">
          <div className="flex justify-center gap-0">
            <img
              src="/assets/images/Landwolf.gif"
              alt="Landwolf_Walking"
              className="size-full relative z-50 md:mb-20 mx-auto "
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;

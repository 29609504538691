import React from "react";
import { Social } from "../Social/Social";
import Ca from "../Ca/Ca";

export const Hero = () => {
  return (
    <div>
      {" "}
      <Ca />
      <div className="min-h-[900px] lg:min-h-0 herobg  xxl:pt-10 relative ">
        <img
          src="/assets/images/Star 1 (1).png"
          alt="Star"
          className="absolute right-0 top-0 xxxl:hidden"
        />
        <div className="max-w-[1700px] mx-auto">
          <div className="flex flex-row-reverse xxl:flex-col justify-center items-center  gap-20  text-white">
            {/* 1 section */}

            <div className="w-full xxl:px-4  xxl:ms-auto lg:mx-auto relative">
              <img
                src="/assets/images/LandwolfPFP.png"
                alt="Landwolf"
                className="size-[80%] xxl:mx-auto xxl:size-[40%]   md:!size-full bounce-animation md:w-full  md:h-full z-10 relative"
              />
            </div>

            {/* 2 section */}
            <div className="w-[50%] lg:w-full flex-1 xxl:px-4">
              <h1 className="font-MonumentExtendedUltrabold text-[100px] xxl:text-6xl md:text-7xl xs:text-4xl uppercase xxl:text-center">
                landwolf
              </h1>

              <h2 className="font-MonumentExtended xl:text-center text-[35px] z-10 relative md:text-2xl md:mt-5 uppercase leading-[2.3]">
                {" "}
                <span className="text-primary">Join the fastest growing </span>meme coin community {" "}
                <span className="text-primary">on Puslechain</span> at the links below. Hell Yeah!
                <div className="xxl:flex justify-center mt-10">
                  <Social />
                </div>
              </h2>
            </div>

            {/* 3 section */}
            <div className="xxl:hidden rotate-90 z-30 bg-primary text-black py-1.5  absolute -right-[500px] w-fit h-fit "></div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { IoCopyOutline, IoCheckmarkOutline } from "react-icons/io5";

const Ca = () => {
  const address = "0xa219C24353B59bEE9F535C4402c11C476A49ACA9";

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div className="flex  z-30 overflow-hidden  text-black bg-primary  relative w-full h-fit xxl:px-4">
      <div className="flex items-center justify-center gap-5 py-3 z-30 text-black relative w-full h-fit">
        <h1 className="font-MonumentExtended break-all text-[26px] md:text-xl text-center">
          {address}
        </h1>
        <div
          className="flex-none cursor-pointer z-20"
          onClick={handleCopyClick}
        >
          {isCopied ? (
            <IoCheckmarkOutline fontSize={24} />
          ) : (
            <IoCopyOutline fontSize={24} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Ca;

import React from "react";
import { InfinityText } from "../InfinityText";

const testimonials1 = [
  {
    quote: "$WOLF ON PULSE",
  },
  {
    quote: "////",
  },
  {
    quote: "FASTER, BETTER, CHEAPER BLOCKCHAIN",
  },
  {
    quote: "////",
  },
  {
    quote: " ZERO TAXES",
  },
  {
    quote: "////",
  },
  {
    quote: "WEEKLY TWITTER SPACES",
  },
  {
    quote: "////",
  },
  {
    quote: "$WOLF ON PULSE",
  },
  {
    quote: "////",
  },
  {
    quote: "FASTER, BETTER, CHEAPER BLOCKCHAIN",
  },
  {
    quote: "////",
  },
  {
    quote: " ZERO TAXES",
  },
  {
    quote: "////",
  },
  {
    quote: "WEEKLY TWITTER SPACES",
  },
  {
    quote: "////",
  },
];

const Car = () => {
  return (
    <div className=" -mt-60 xxl:mt-20   md:mt-20 relative  overflow-hidden">
      <InfinityText items={testimonials1} direction="right" speed="fast" />
    </div>
  );
};

export default Car;

import React from "react";

import { Social } from "../Social/Social";
import Ca from "../Ca/Ca";

const Supply = () => {
  return (
    <div className="relative z-10">
      <img
        src="/assets/images/circle2.png"
        className="absolute left-0 -top-80  h-[60%] hidden"
        alt="circle2"
      />

      <div className="max-w-5xl  lg:mx-4 relative z-10 mx-auto bg-[#7a4bb8] flex md:flex-col md:divide-x-0 md:divide-y-0 justify-center items-center divide-x divide-primary/20 rounded-[34px] my-20 p-20">
        <div className="px-10 md:py-10 md:border-b border-primary/20 text-center">
          <p className="font-MonumentExtended text-[30px]">420.69M</p>
          <p className="text-primary font-MonumentExtended uppercase text-xl">
            total supply
          </p>
        </div>

        <div className="px-10 md:py-10 md:!border-b border-primary/20 text-center">
          <p className="font-MonumentExtended text-[30px] text-nowrap">0 / 0</p>
          <p className="text-primary font-MonumentExtended uppercase text-xl">
            tax
          </p>
        </div>

        <div className="px-10 md:py-10 text-center">
          <p className="font-MonumentExtended text-[30px]">Audited/Renounced</p>
          <p className="text-primary font-MonumentExtended uppercase text-xl">
            Contract Status
          </p>
        </div>
      </div>

      <h1 className="font-MonumentExtended break-all mb-20 text-[30px] underline text-center xxl:px-4">
        <a href="mailto:">landwolf@omnihq.pro</a>
      </h1>
      <div className=" flex justify-center mb-20">
        <Social />
      </div>

      <Ca />
    </div>
  );
};

export default Supply;
